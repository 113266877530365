.prevnextbtns {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
}

.prevnextbtns > button {
  padding: 15px 35px;
  background-color: var(--color-bg-2);
  border: none;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
}

.prevnextbtns > button > img {
  width: 15px;
}

.prevnextbtns > button:nth-child(1) {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  background-color: #c7c9c8;
}

.prevnextbtns > button:nth-child(1) > img {
  transform: rotateZ(180deg);
}

.prevnextbtns > button:nth-child(2) {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}
